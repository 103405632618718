export enum RolesEnum {
  ANONYMOUS = 'anonymous',
  NONE = 'none',
  ADMIN = 'admin',
  MODERATOR = 'moderator',
  COMPANY = 'company',
  TEACHER = 'teacher',
  STUDENT = 'student',
  OBSERVER = 'observer',
  COMMITTEE = 'committee'
}
