import { version } from './version';

export const environment = {
  production: false,
  hmr: false,
  version,
  keys: {
    token: "TOKEN_PROXY_KEY",
    refreshToken: "REFRESH_TOKEN_PROXY_KEY",
    user: "USER_PROXY_KEY",
  },
  flags: {
    professor: true,
  },
  config: {
    internalCompanyId: 1,
    redirectToWhenAuthenticated: "/pages/dashboard",
    redirectToWhenUnauthenticated: "/auth/login",
  },
  api: {
    baseUrl: "https://api-test.conecta-facens.ligafacens.com.br",
    auth: {
      login: "/auth/local",
    },
    location: {
      states: '/location/states',
      cities: '/location/state/{stateCode}/cities'
    },
    companies: {
      list: "/companies",
      get: "/companies/{companyId}",
      create: "/companies",
      update: "/companies/{companyId}",
    },
    notices: {
      list: "/notices",
      get: "/notices/{noticeId}",
      create: "/notices",
      update: "/notices/{noticeId}",
    },
    users: {
      list: "/users",
      get: "/users/{userId}",
      create: "/users",
      update: "/users/{userId}",
      me: "/users/me",
    },
    spam: {
      upload: '/spam/upload',
    },
    email: {
      spamPreview: '/email/spam/preview',
    },
    projects: {
      list: "/projects",
      get: "/projects/{projectId}",
      export: "/projects/{projectId}/export",
      create: "/projects",
      update: "/projects/{projectId}",
      medias: "/projects/media",
      correctionMedias: "/projects/corrections/media",
      professor: {
        attach: '/projects/{projectId}/professor',
      }
    },
    userPassword: {
      firstAccess: "/users/password/{id}",
      forgotPassword: "/users/password/forgot/email/{email}",
      resetPassword: "/users/password/reset/{resetPasswordCode}",
    },
    themes: {
      list: "/themes",
      get: "/themes/{themeId}",
      create: "/themes",
      update: "/themes/{themeId}",
    },
    banners: {
      list: "/banners",
      get: "/banners/{bannerId}",
      create: "/banners",
      update: "/banners/{bannerId}",
      reorder: "/banners/reorder"
    },
    contacts: {
      list: "/contacts",
      get: "/contacts/{contactId}",
      create: "/contacts",
      update: "/contacts/{contactId}",
    },
    logs: {
      list:"/logs",
      get: "/logs/{id}",
      create:"/logs/{id}",
      update:"/logs/{id}"
    },
    professors: {
      list: '/professors',
      verify: '/professors/facens/verify/{email}'
    }
  },
};
