//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { RolesEnum } from '../../models/enums/roles.enum';
import { UserService } from '../../services/user/user.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {

  //#region Constructor

  constructor(
    protected readonly router: Router,
    protected readonly user: UserService,
  ) { }

  //#endregion

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const { unprotectedRoute, protectedRoute, routeToRedirect } = route.data || {};
    const shouldLogout = route.queryParamMap.get('shouldLogout');

    if (shouldLogout) {
      localStorage.clear();
      this.user.refreshCurrentUser();
    }

    if (!routeToRedirect)
      return true;

    const currentUser = this.user.getCurrentUser();

    if (currentUser?.roles?.includes(RolesEnum.COMMITTEE) && !currentUser?.hasChangedPassword)
      if (state.url !== '/first-password')
        return await this.router.navigate(['/first-password'], { replaceUrl: true }).then(() => false);

    const hasToken = !!localStorage.getItem(environment.keys.token);

    if (hasToken && protectedRoute)
      return true;

    if (!hasToken && unprotectedRoute)
      return true;

    return await this.router.navigate([routeToRedirect], { replaceUrl: true }).then(() => false);
  }
}
